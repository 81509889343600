//  libraries
import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

//  styles
import FirstSectionWrapper, { BackgroundContainer } from './firstSection.style';

export default function () {
  const data = useStaticQuery(graphql`
  {
    strapiJoinOurTeam {
      data {
        firstSection {
          title
          content
        }
      }
      backgroundImgFirstSection {
        childImageSharp {
          fluid (quality:100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`);

  return (
    <BackgroundContainer fluid={data.strapiJoinOurTeam.backgroundImgFirstSection.childImageSharp.fluid}>
      <FirstSectionWrapper>
        <h1>{data.strapiJoinOurTeam.data.firstSection.title}</h1>
        <p>{data.strapiJoinOurTeam.data.firstSection.content}</p>
      </FirstSectionWrapper>
    </BackgroundContainer>
  )
}