//  libraries
import React from 'react'

//  components
import Layout from 'components/layout'
import SEO from 'components/seo'

//  containers
import FirstSection from 'containers/JoinOurTeam/FirstSection'
import SecondSection from 'containers/JoinOurTeam/SecondSection'
import ThirdSection from 'containers/JoinOurTeam/ThirdSection'
import FourthSection from 'containers/JoinOurTeam/FourthSection'

export default function () {
  return (
    <Layout>
      <SEO title="Join Our Team" />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
    </Layout>
  )
}
