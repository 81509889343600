//  libraries
import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

//  hooks
import { useNearScreen } from 'hooks/useNearScreen';

//  styles
import SecondSectionWrapper, { JobsItems } from './secondSection.style';

export default function () {
  const [show, ref] = useNearScreen();

  const data = useStaticQuery(graphql`
  {
    strapiJoinOurTeam {
      data {
        secondSection {
          title
          jobs {
            title
            subtitle
            content
            button
            buttonRoute
          }
          button
          buttonRoute
        }
      }
    }
  }
`);

  return (
    <SecondSectionWrapper ref={ref}>
      <h1>{data.strapiJoinOurTeam.data.secondSection.title}</h1>
      {show &&
        (
          data.strapiJoinOurTeam.data.secondSection.jobs.map((item, index) => {
            return (
              <JobsItems key={index}>
                <div className="recent-jobs-container">
                  <div>
                    <h3>{item.title}</h3>
                    <p>{item.subtitle}</p>
                    <p>{item.content}</p>
                  </div>
                  <div className="recent-jobs-div-button">
                    <a href={item.buttonRoute}><button>{item.button}</button></a>
                  </div>
                </div>
                <hr />
              </JobsItems>
            )
          })
        )
      }
      {show &&
        <a href={data.strapiJoinOurTeam.data.secondSection.buttonRoute}>
          <button>{data.strapiJoinOurTeam.data.secondSection.button}</button>
        </a>
      }
    </SecondSectionWrapper>
  )
}