import styled from 'styled-components';
import { moveInLeft } from 'styles/animation'
import BackgroundImage from 'gatsby-background-image';

export const BackgroundContainer = styled(BackgroundImage)`
  padding: 127px 0 88px;
  height: 462px;

  @media (max-width: 700px) {
    height: 90vh;
  }
`;

const MainSectionWrapper = styled.div`
  ${moveInLeft('.8s', 'ease-out')}
  display: grid;
  grid-auto-flow: row;
  text-align: center;
  opacity: 1;

  @media (max-width: 700px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }

  & h1 {
    width: 80%;
    font-weight: 800;
    letter-spacing: 0px;
    font-size: 37px;
    line-height: 40px;
    color: #ffffff;
    justify-self: center;
    opacity: 1 !important;

    @media (min-width: 720px) and (max-width: 1038px) {
      font-size: 50px;
    }

    @media (min-width: 1038px) {
      font-size: 45px;
      line-height: 8rem;
    }
  }

  & p {
    width: 80%;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #ffffff;
    padding-top: 5rem;
    margin: 0;
    text-align: center;
    justify-self: center;
    opacity: 1;

    @media (min-width: 720px) and (max-width: 1038px) {
      font-size: 25px;
    }

    @media (min-width: 1038px) {
      max-width: 932px;
      font-size: 22px;
    } 
  }
`;

export default MainSectionWrapper;
