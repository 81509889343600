import styled from 'styled-components';
import { moveInBottom, moveInLeft } from 'styles/animation'

const RecentJobsWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  text-align: center;
  margin: 110px 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;

  & h1 {
    ${moveInBottom};
    width: 80%;
    font-weight: 800;
    letter-spacing: 0px;
    font-size: 37px;
    line-height: 40px;
    color: #373F49;
    justify-self: center;
    margin-bottom: 8rem;
    opacity: 1 !important;

    @media (min-width: 720px) and (max-width: 1038px) {
      font-size: 50px;
    }

    @media (min-width: 1038px) {
      font-size: 45px;
      line-height: 8rem;
    }
  }
  
  & button {
    width: 80%;
    height: 45px;
    margin-top: 30px;
    background: #147efb;
    border: 4px solid #147efb;
    border-radius: 50px;
    color: white;
    justify-self: center;
    font-weight: 500;
    font-size: 1.6rem;
    font-family: 'Poppins';

    @media (min-width: 1024px) {
      width: 45%;
    }

    &:active {
      position: relative;
      top: 2px;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      background: #147efb;
      border: 4px solid #147efb;
    }
  }
`;

export const JobsItems = styled.div`
  ${moveInLeft('2s', 'ease-out')}
  justify-self: center; 
  width: 80%;

  @media (min-width: 1024px) {
    width: 45%;
  }

  & .recent-jobs-container {
    display: grid;
    grid-auto-flow: row;
    margin-bottom: 10px;

    @media (min-width: 720px) {
      grid-auto-flow: column;
    }
  }

  & h3 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #788293;
    margin-top: 15px;

    @media (min-width: 720px) and (max-width: 1038px) {
      font-size: 25px;
    }

    @media (min-width: 1038px) {
      text-align: initial;
      font-size: 22px;
    }
  }

  & p {
    width: 100%;
    justify-self: center;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    line-height: 27px;
    color: #788293;
    opacity: 1;

    @media (min-width: 720px) and (max-width: 1038px) {
      font-size: 16px;
    }

    @media (min-width: 1038px) {
      width: 80%;
      font-size: 16px;
      text-align: initial;
    }
  }

  & button {
    width: 150px;
    height: 45px;
    margin: 15px 0;
    background-color: white;
    border: 4px solid #147efb;
    border-radius: 50px;
    opacity: 1;
    color: #147efb;
    font-weight: 500;
    font-size: 1.6rem;
    font-family: 'Poppins';

    @media (max-width: 720px) {
      width: 100%;
    }

    @media (min-width: 720px) {
      margin: 0;
    }

    &:hover {
      background: #147efb;
      color: white;
    }
  }

  & .recent-jobs-div-button {
    align-self: center;
  }
`;

export default RecentJobsWrapper;
